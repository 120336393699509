import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import useTablePagination from '@/hooks/pagination';
import labelManagement from './components/labelManagement.vue';
import { insertDepartment, updateDepartment, deleteDepartment, selectDepartmentByPagination, insertRank, updateRank, deleteRank, selectRankByPagination, insertWorkPlace, updateWorkPlace, deleteWorkPlace, selectWorkPlaceByPagination, selectJobByPagination } from '@/api/personnel';
import { getPosts, addPosts, setPosts, deletePosts, queryEmployee, jobQuery, jobModify } from '@/api/role';
import Cookies from "js-cookie";
//表头
const columns = [{
  title: '名称',
  dataIndex: 'name'
}, {
  title: '描述',
  dataIndex: 'described'
}, {
  title: '创建人',
  dataIndex: 'creator'
}, {
  title: '创建时间',
  dataIndex: 'createTime'
}, {
  title: '更新人',
  dataIndex: 'modifier'
}, {
  title: '更新时间',
  dataIndex: 'updateTime'
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 230,
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    labelManagement
  },

  setup() {
    const activeKey = ref('1');
    const panes = ref([{
      key: '1',
      title: '部门'
    }, {
      key: '2',
      title: '岗位'
    }, {
      key: '3',
      title: '职级'
    }, {
      key: '4',
      title: '工作地点'
    }]); //屏幕宽度

    const getClientWidth = ref(0);

    const getClientWidthFn = () => {
      if (document.body.clientWidth > 1440) {
        getClientWidth.value = '490px';
      } else {
        getClientWidth.value = '540px';
      }
    };

    const handleChange = key => {
      current.value = 1;
      formState.name = "";
      getTableData();
    };

    const formRef = ref();
    const formState = reactive({
      name: ''
    }); //查询

    const onSubmit = () => {
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      // formRef.value.resetFields();
      formState.name = null;
      getTableData();
    };

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination();
    const loading = ref(false); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const tableData = ref([]); //获取表格数据

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        workPlaceName: formState.name,
        departmentName: formState.name,
        rankName: formState.name,
        jobName: formState.name
      };
      let res = null;
      let labelName = null;

      switch (activeKey.value) {
        case '1':
          res = await selectDepartmentByPagination(param);
          labelName = 'departmentName';
          break;

        case '2':
          res = await selectJobByPagination(param);
          labelName = 'jobName';
          break;

        case '3':
          res = await selectRankByPagination(param);
          labelName = 'rankName';
          break;

        case '4':
          res = await selectWorkPlaceByPagination(param);
          labelName = 'workPlaceName';
          break;
      } // console.log(res)


      if (res.code === 200) {
        tableData.value = [];
        res.data.list.forEach((item, index) => {
          tableData.value.push({ ...item,
            name: item[labelName],
            key: item.id
          });
        });
        total.value = res.data.countSize;
        current.value = res.data.nowPage;
        pageSize.value = res.data.pageSize;
        loading.value = false;
      } else {
        _message.error(res.message);

        loading.value = false;
      }
    }; //新增


    const formRefForModel = ref();
    const formStateForModel = reactive({
      name: null,
      described: null,
      id: null
    });
    const rules = {
      name: [{
        required: true,
        message: '名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      described: [{
        required: true,
        message: '描述不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };
    const modalTitle1 = ref('新建');

    const hanadleAdd = () => {
      visible.value = true;
      modalTitle1.value = '新建';
      formStateForModel.id = null;
      formStateForModel.name = null;
      formStateForModel.described = null;
    };

    const modalTitle = computed(() => {
      return panes.value.find(item => item.key === activeKey.value).title;
    });
    const visible = ref(false);

    const modification = val => {
      visible.value = true;
      modalTitle1.value = '修改';
      formStateForModel.name = val.name;
      formStateForModel.described = val.described;
      formStateForModel.id = val.key;
    };

    const adLoading = ref(false);

    const handleOk = () => {
      formRefForModel.value.validate().then(async () => {
        adLoading.value = true;
        const param = {
          workPlaceName: formStateForModel.name,
          departmentName: formStateForModel.name,
          rankName: formStateForModel.name,
          jobName: formStateForModel.name,
          described: formStateForModel.described,
          jobType: 1,
          id: formStateForModel.id,
          jobNO: formStateForModel.id
        };
        let res = null;

        if (modalTitle1.value == '新建') {
          switch (activeKey.value) {
            case '1':
              res = await insertDepartment(param);
              break;

            case '2':
              res = await addPosts(param);
              break;

            case '3':
              res = await insertRank(param);
              break;

            case '4':
              res = await insertWorkPlace(param);
              break;
          }
        }

        if (modalTitle1.value == '修改') {
          switch (activeKey.value) {
            case '1':
              res = await updateDepartment(param);
              break;

            case '2':
              res = await setPosts(param);
              break;

            case '3':
              res = await updateRank(param);
              break;

            case '4':
              res = await updateWorkPlace(param);
              break;
          }
        }

        if (res.code === 200) {
          _message.success(res.message);

          visible.value = false;
          getTableData();
        } else {
          _message.error(res.message);
        }

        adLoading.value = false;
      }).catch(error => {
        console.log('error', error);
        adLoading.value = false;
      });
    }; //删除


    const onDelete = async key => {
      let res = null;
      const param = {
        id: key
      };

      switch (activeKey.value) {
        case '1':
          res = await deleteDepartment(param);
          break;

        case '2':
          res = await deletePosts(param);
          break;

        case '3':
          res = await deleteRank(param);
          break;

        case '4':
          res = await deleteWorkPlace(param);
          break;
      }

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const handleCancel = () => {
      formRefForModel.value.resetFields();
      visible.value = false;
    }; //按钮权限


    const getBtnjurisdiction = val => {
      let str1 = null;
      let str2 = null;

      switch (val) {
        case 1:
          str2 = '新建';
          break;

        case 2:
          str2 = '修改';
          break;

        case 3:
          str2 = '删除';
          break;
      }

      switch (activeKey.value) {
        case '1':
          str1 = '部门';
          break;

        case '2':
          str1 = '岗位';
          break;

        case '3':
          str1 = '职级';
          break;

        case '4':
          str1 = '工作地点';
          break;
      }

      return `organizationManagement:${str1}${str2}`;
    };

    const menuGenre = Number(Cookies.get("menuGenre")); //工人岗位弹窗

    const labelVisible = ref(false);

    const handleLable = () => {
      labelVisible.value = true;
    };

    onMounted(() => {
      getClientWidthFn();

      window.onresize = function () {
        getClientWidthFn();
      };

      getTableData();
    });
    onBeforeUnmount(() => {
      window.onresize = null;
    });
    return {
      activeKey,
      handleChange,
      formRef,
      formState,
      onSubmit,
      resetForm,
      columns,
      hanadleAdd,
      modalTitle,
      getClientWidth,
      paginationConfig,
      loading,
      onPageChange,
      visible,
      formRefForModel,
      formStateForModel,
      rules,
      panes,
      modalTitle1,
      modification,
      onDelete,
      tableData,
      getBtnjurisdiction,
      handleOk,
      adLoading,
      menuGenre,
      labelVisible,
      handleLable,
      handleCancel
    };
  }

});